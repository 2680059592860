import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@gatsbyjs/reach-router';
import styled from 'styled-components';
import { Small } from 'components/common/Typography';
import Grid from 'components/common/Grid';
import Logo from 'icons/Logo';
import { useParticipant } from 'hooks/useParticipant';
import { useSignOut } from 'hooks/useSignOut';

const Wrapper = styled.header`
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 0;
  transition: color 0.5s ease;
  width: 100%;
  z-index: 10;
  color: ${({ $colorTheme, theme }) =>
    $colorTheme === 'light'
      ? theme.colors.grey[50]
      : theme.colors.charcoal[600]};
`;

const Inner = styled(Grid)`
  height: var(--bookends);
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const TimelineLogo = styled(Logo)`
  color: currentColor;
  height: 20px;
`;

const Nav = styled.nav`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: calc(var(--spacing) * 2);
  text-transform: uppercase;
  font-family: 'GT America Mono';
  color: currentColor;
`;

export default function Header({
  className,
  siteTitle,
  colorTheme,
  activePath,
}) {
  const { data: isLoggedIn } = useParticipant();
  const signOut = useSignOut();
  const isHome = activePath === '/';
  return (
    <Wrapper className={className} $colorTheme={colorTheme}>
      <Inner>
        <a href="/">
          <TimelineLogo>{siteTitle}</TimelineLogo>
        </a>
        <Nav>
          {isLoggedIn && !isHome && (
            <Small
              as={Link}
              to="/participant"
              onClick={(event) => {
                event.preventDefault();
                signOut();
              }}
            >
              Sign out
            </Small>
          )}
          {isHome && (
            <Small as="a" href="/participant">
              Sign in
            </Small>
          )}
          {!isLoggedIn && !isHome && (
            <Small as="a" href="/">
              About
            </Small>
          )}
        </Nav>
      </Inner>
    </Wrapper>
  );
}

Header.propTypes = {
  activePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  siteTitle: PropTypes.string,
  colorTheme: PropTypes.oneOf(['dark', 'light']),
};
