import PropTypes from 'prop-types';
import * as React from 'react';

function Facebook({ title, titleId = 'Facebook', ...props }) {
  return (
    <svg
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.5 0C4.253 0 0 4.054 0 9.055c0 4.52 3.474 8.266 8.016 8.945v-6.328H5.604V9.055h2.412V7.06c0-2.27 1.418-3.523 3.588-3.523 1.04 0 2.127.177 2.127.177v2.228h-1.198c-1.18 0-1.549.698-1.549 1.414v1.699h2.635l-.421 2.617h-2.214V18C15.526 17.32 19 13.575 19 9.055 19 4.055 14.747 0 9.5 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Facebook;

Facebook.propTypes = {
  title: PropTypes.string,
  titleId: PropTypes.string,
};
