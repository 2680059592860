import PropTypes from 'prop-types';
import * as React from 'react';

function Email({ title, titleId = 'Email', ...props }) {
  return (
    <svg
      viewBox="0 0 441 352"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={title ? titleId : null}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M392 16H56c-22.091 0-40 17.909-40 40v240c0 22.091 17.909 40 40 40h336c22.091 0 40-17.909 40-40V56c0-22.091-17.909-40-40-40z"
        stroke="currentColor"
        strokeWidth={32}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 80l144 112L368 80"
        stroke="currentColor"
        strokeWidth={32}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Email;

Email.propTypes = {
  title: PropTypes.string,
  titleId: PropTypes.string,
};
