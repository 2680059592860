import PropTypes from 'prop-types';
import * as React from 'react';

function Logo({ title, titleId = 'Timeline', ...props }) {
  return (
    <svg
      viewBox="0 0 135 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={title ? titleId : null}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M95.174 0h2.364v17.928h-2.364V0zM103.446 0h-2.364v2.53h2.364V0zM103.446 4.366h-2.364v13.562h2.364V4.366zM119.411 9.49v8.438h-2.364V9.544c0-2.274-1.325-3.616-3.631-3.616-2.564 0-4.064 1.698-4.064 4.575v7.425h-2.363V4.366h2.363v4.657h.058c0-3.095 1.815-4.932 4.841-4.932 3.258 0 5.16 2 5.16 5.398zM91.63 10.177H58.731v1.667h32.897v-1.667zM5.869 15.681c-.663 0-1.202-.51-1.202-1.142V6.037h3.545V4.366H4.67V0H2.306v4.366H0v1.671h2.306v8.521c0 1.861 1.587 3.37 3.545 3.37h2.363v-2.247H5.869zM128.402 4.09c-4.15 0-6.628 2.604-6.628 7.069 0 4.439 2.478 7.04 6.628 7.04 3.551 0 5.948-1.729 6.533-4.69h-2.347c-.485 1.817-2.016 2.91-4.186 2.91-2.492 0-4.049-1.675-4.27-4.577H135v-.681c.002-4.467-2.448-7.07-6.598-7.07zm0 1.781c2.394 0 3.931 1.574 4.215 4.304h-8.458c.284-2.73 1.849-4.304 4.243-4.304zM14.12 4.366h-2.363v13.562h2.363V4.366zM39.596 9.353v8.575h-2.363v-8.52c0-2.193-1.297-3.48-3.488-3.48-2.478 0-3.949 1.644-3.949 4.439v7.561h-2.363v-8.52c0-2.193-1.268-3.48-3.488-3.48-2.478 0-3.918 1.644-3.918 4.439v7.561h-2.361V4.366h2.363v4.548h.057c0-3.04 1.758-4.822 4.756-4.822 3.14 0 4.956 1.918 4.956 4.822h.058c0-3.04 1.758-4.822 4.726-4.822 3.17-.001 5.014 1.945 5.014 5.26zM48.588 4.09c-4.15 0-6.629 2.604-6.629 7.069 0 4.439 2.478 7.04 6.629 7.04 3.55 0 5.947-1.729 6.533-4.69h-2.347c-.485 1.817-2.017 2.91-4.186 2.91-2.493 0-4.05-1.675-4.27-4.577h10.867v-.681c.002-4.467-2.447-7.07-6.597-7.07zm0 1.781c2.393 0 3.93 1.574 4.214 4.304h-8.458c.286-2.73 1.85-4.304 4.244-4.304zM14.12 0h-2.363v2.53h2.363V0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Logo;

Logo.propTypes = {
  title: PropTypes.string,
  titleId: PropTypes.string,
};
