import { between } from 'polished';

export default function lock(
  fromSize,
  toSize,
  fromBreakpoint = 'mobilePortrait',
  toBreakpoint = 'desktop'
) {
  return function getStyle({ theme }) {
    const fluid = between(
      fromSize,
      toSize,
      `${theme.breakpoints[fromBreakpoint]}px`,
      `${theme.breakpoints[toBreakpoint]}px`
    );
    return `clamp(${fromSize}, ${fluid}, ${toSize})`;
  };
}
