import PropTypes from 'prop-types';
import * as React from 'react';

function Instagram({ title, titleId = 'Instagram', ...props }) {
  return (
    <svg
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={title ? titleId : null}
      fill="currentColor"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13.28 0H5.212A4.973 4.973 0 00.246 4.967v8.065A4.972 4.972 0 005.213 18h8.065a4.973 4.973 0 004.968-4.967V4.967A4.973 4.973 0 0013.28 0zm3.369 13.033a3.373 3.373 0 01-3.37 3.37H5.212a3.374 3.374 0 01-3.37-3.37V4.967a3.374 3.374 0 013.37-3.37h8.065a3.375 3.375 0 013.371 3.37v8.066z" />
      <path d="M9.246 4.362A4.643 4.643 0 004.608 9a4.643 4.643 0 004.638 4.638A4.643 4.643 0 0013.884 9a4.643 4.643 0 00-4.638-4.638zm0 7.679A3.044 3.044 0 016.206 9a3.044 3.044 0 013.04-3.04A3.044 3.044 0 0112.287 9a3.044 3.044 0 01-3.04 3.04zM14.079 3.008a1.176 1.176 0 00-1.171 1.171c0 .308.125.61.344.828.217.218.52.343.827.343.308 0 .61-.125.828-.343a1.176 1.176 0 000-1.657 1.176 1.176 0 00-.828-.342z" />
    </svg>
  );
}

export default Instagram;

Instagram.propTypes = {
  title: PropTypes.string,
  titleId: PropTypes.string,
};
