import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Facebook from 'icons/Facebook';
import Instagram from 'icons/Instagram';
import Email from 'icons/Email';
import Grid from 'components/common/Grid';
import { Link } from 'gatsby';
import { Small } from 'components/common/Typography';
import zIndex from 'utils/zIndex';

const Wrapper = styled.footer`
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: ${zIndex('footer')};
  color: ${({ $colorTheme, theme }) =>
    $colorTheme === 'light'
      ? theme.colors.grey[50]
      : theme.colors.charcoal[400]};
`;

const Inner = styled(Grid)`
  height: var(--bookends);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SocialMedia = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: var(--spacing);
`;

const SmallPrint = styled.nav`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: var(--spacing);
`;

const SocialItem = styled.a`
  color: currentColor;
  svg {
    height: calc(var(--spacing) / 1.25);
  }
`;

export default function Footer({ className, colorTheme }) {
  return (
    <Wrapper className={className} $colorTheme={colorTheme}>
      <Inner>
        <SmallPrint>
          <Small>
            &copy;&nbsp;
            {new Date().getFullYear()}
          </Small>
          <Small as={Link} to="/terms-and-conditions">
            Terms & Conditions
          </Small>
          <Small as={Link} to="/privacy-policy">
            Privacy Policy
          </Small>
        </SmallPrint>
        <SocialMedia>
          <SocialItem href="hhttps://www.facebook.com/TimelineNutrition/">
            <Facebook />
          </SocialItem>
          <SocialItem href="https://www.instagram.com/timelinenutrition/">
            <Instagram />
          </SocialItem>
          <SocialItem href="mailto:challenge@timeline.com">
            <Email />
          </SocialItem>
        </SocialMedia>
      </Inner>
    </Wrapper>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  colorTheme: PropTypes.oneOf(['dark', 'light']),
};
