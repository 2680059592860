module.exports = {
  breakpoints: {
    mobilePortrait: 320,
    mobileLandscape: 550,
    tabletPortrait: 767,
    tabletLandscape: 1024,
    laptop: 1240,
    desktop: 1920,
  },
  colors: {
    charcoal: {
      200: '#8CA6BA',
      300: '#476279',
      400: '#273B4D',
      500: '#1B2936',
      600: '#0A161B',
    },
    grey: {
      50: '#F7F7F5',
      75: '#EEEEEC',
      100: '#E5E5E4',
      200: '#D0D0CF',
      400: '#A3A3A3',
      600: '#70706F',
    },
    red: {
      100: '#E97274',
      300: '#C23F41',
      500: '#963637',
    },
  },
};
