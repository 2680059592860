import { createGlobalStyle } from 'styled-components';
import lock from 'utils/lock';
import { hideVisually } from 'polished';

export default createGlobalStyle`

  :root {
    /* Sizes */
    --bodyFontSize: ${lock('16px', '18px')};
    --spacing: calc(var(--bodyFontSize) * 1.5);
    --sectionSpacing: ${lock('48px', '96px')};
    --bookends: ${lock('80px', '96px')};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  html {
    font-family: 'Suisse Intl', sans-serif;
    color: ${({ theme }) => theme.colors.charcoal[600]};
    background: ${({ theme }) => theme.colors.grey[50]};
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Remove default margins */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  body {
    overscroll-behavior-y: none;
    overflow-y: scroll;
    text-rendering: optimizeSpeed;
    position: relative;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video  {
    max-width: 100%;
    vertical-align: middle;
  }

  a {
    color: inherit;
    text-decoration: none;
    /* A elements that don't have a class get default styles */
    &:not([class]) {
      text-decoration-skip-ink: auto;
    }
  }

  p a {
    text-decoration: underline;
  }

  figure,
  blockquote {
    margin: 0;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  input,
  button,
  textarea,
  select {
    font-family: inherit;
    font-size: inherit;
  }

  textarea {
    resize: vertical;
  }

  address {
    font-style: normal;
    white-space: pre;
  }

  ul, ol {
    padding-left: 0;
    list-style-position: inside;
  }

  .grecaptcha-badge {
    ${hideVisually()};
   }
  
  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;
