import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'utils/globalStyles';
import theme from 'utils/theme';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import { create } from 'zustand';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const useStore = create((set) => ({
  header: 'light',
  footer: 'light',
  setTheme: ({ header, footer }) => set(() => ({ header, footer })),
}));

export function useTheme({ header, footer }) {
  const { setTheme } = useStore();
  useLayoutEffect(() => {
    setTheme({ header, footer });
  }, [setTheme, header, footer]);
}

export default function Layout({ children, location }) {
  const { header, footer } = useStore();

  // Use `useStaticQuery` here
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header
          siteTitle={data.site.siteMetadata.title}
          colorTheme={header}
          activePath={location.pathname}
        />
        {children}
        <Footer title={data.site.siteMetadata.title} colorTheme={footer} />
        <ReactQueryDevtools initialIsOpen />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};
