import { useQuery } from 'react-query';
import axios from 'axios';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

export async function getParticipant() {
  const token = localStorage.getItem('token');
  if (!token) return false;
  const res = await axios.get(`/api/get-participant`, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  return res.data;
}

export function useParticipant() {
  return useQuery(['participant'], getParticipant, {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    onSuccess: () => {
      trackCustomEvent({
        category: 'signInSuccess',
        action: 'SignIn',
        label: 'Success',
      });
    },
    onError: (error) => {
      trackCustomEvent({
        category: 'signInError',
        action: 'SignIn',
        label: `Error: ${error.message}`,
      });
    },
  });
}
