import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

export function useSignOut() {
  const queryCache = useQueryClient();

  const signOut = useCallback(() => {
    localStorage.removeItem('token');
    queryCache.refetchQueries('participant');
  }, [queryCache]);

  return signOut;
}
