import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--spacing);
  width: 90%;
  max-width: calc(1920px - 10%);
  margin: auto;
`;
