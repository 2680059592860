import styled from 'styled-components';
import lock from 'utils/lock';

export const H1 = styled.h1`
  font-size: ${lock('32px', '74px')};
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: -0.025em;
`;

export const H2 = styled.h2`
  font-size: ${lock('24px', '44px')};
  font-weight: 400;
  line-height: 1.333em;
`;

export const H3 = styled.h3`
  font-size: ${lock('21px', '32px')};
  font-weight: 400;
  line-height: 1.2em;
`;

export const H4 = styled.h4`
  font-size: ${lock('18px', '20px')};
  font-weight: 600;
  line-height: calc(var(--spacing) * 1);
`;

export const P = styled.p`
  font-size: ${lock('15px', '17px')};
  line-height: 1.5em;
`;

export const Small = styled.p`
  font-size: ${lock('12px', '14px')};
  line-height: 1.5em;
`;
